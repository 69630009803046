<template>
    <b-container fluid>

        <b-row class="mb-2">
            <b-col>
                <b-form-input type="text" v-model="filter.num" placeholder="Номер"/>
            </b-col>
            <b-col>
                <b-form-select v-model="filter.status" :options="statusOptions"/>
            </b-col>
            <b-col>
                <b-form-select v-model="filter.courierCode" :options="courierCodeOptions"/>
            </b-col>
            <b-col>
                <b-form-input type="text" v-model="filter.courierNum" placeholder="Номер отслеживания КС"/>
            </b-col>
            <b-col>
                <b-form-input type="text" v-model="filter.dlvId" placeholder="DLV ID"/>
            </b-col>
        </b-row>

        <b-table stacked="md" class="text-center" :items="entities" :fields="tableFields">
            <template v-slot:cell(actions)="data">
                <b-link class="ml-4" :to="{ name: 'shipment-editor', params: {id: data.item.id}}">
                    Просмотр
                </b-link>
                <b-link @click="remove(data.item.id)">Удалить</b-link>
            </template>
        </b-table>
        <pagination :total-elements="pagination.totalElements" @change="paginationChange"/>
    </b-container>
</template>

<script>

    import Pagination from "../Pagination.vue";

    export default {
        name: "ShipmentBrowser",
        components: {Pagination},
        created() {
            this.getEntities();
        },
        watch: {
            filter: {
                handler() {
                    this.getEntities();
                },
                deep: true
            }
        },
        data() {
            return {
                entities: [],
                pagination: {
                    currentPage: 1,
                    totalElements: 0,
                    pageSize: 20,
                },
                filter: {
                    num: '',
                    status: null,
                    courierNum: '',
                    courierCode: null,
                    dlvId: null
                },
                tableFields: [
                    {
                        key: 'num',
                        label: 'Номер'
                    }, {
                        key: 'shipmentType',
                        label: 'Тип'
                    }, {
                        key: 'dlvLogin',
                        label: 'Аккаунт'
                    }, {
                        key: 'status',
                        label: 'Статус',
                        formatter: value => this.translateStatus(value)
                    }, {
                        key: 'courierCode',
                        label: 'Служба доставки',
                    }, {
                        key: 'courierNum',
                        label: 'Номер отслеживания КС',
                    },{
                        key: 'dlvId',
                        label: 'DLV Id',
                    }, {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
                statusOptions: [
                    {
                        value: null,
                        text: 'Статус',
                    }, {
                        value: 'SENDER',
                        text: 'У отправителя'
                    }, {
                        value: 'COURIER',
                        text: 'У курьера'
                    }, {
                        value: 'SORT',
                        text: 'На складе'
                    }, {
                        value: 'COURIER2',
                        text: 'Доставляется получателю'
                    }, {
                        value: 'PROBLEM',
                        text: 'Проблема'
                    }, {
                        value: 'DELIVERED',
                        text: 'Доставлен'
                    }, {
                        value: 'RETURNED',
                        text: 'Возвращён'
                    }, {
                        value: 'AT_TAKEOUT_POINT',
                        text: 'В пункте самовывоза'
                    }, {
                        value: 'RETURNING',
                        text: 'Возвращается отправителю'
                    }, {
                        value: 'LOST',
                        text: 'Потерян'
                    }, {
                        value: 'PARTIALLY_DELIVERED',
                        text: 'Частично доставлен'
                    },
                ],
                courierCodeOptions: [
                    {
                        value: null,
                        text: 'Служба доставки'
                    },
                    'SPSR',
                    'CityExpress',
                    'PickPoint',
                    'IML',
                    'Hermes',
                    'CDEK',
                    'Grastin',
                    'Boxberry',
                    'DPD',
                    'GD',
                    'RussianPost',
                    'RussianPostApi',
                    'MaxiPost',
                    'PickUp',
                    'PonyExpress',
                    'YandexDelivery',
                    'CSE',
                    'DOSTAVISTA',
                    'PEK',
                    'TEMPOLINE_RUSSIAN_POST',
                    'STRIZH',
                    'Logsis',
                    'A1'
                ],
            }
        },
        methods: {
            getEntities() {
                this.$http.get(this.$root.apiUrl + '/api/shipments',
                    {
                        params: {
                            page: this.pagination.currentPage - 1,
                            size: this.pagination.pageSize,
                            filter: JSON.stringify([
                                {
                                    key: "num",
                                    value: this.filter.num,
                                    operation: "="
                                },
                                {
                                    key: "status",
                                    value: this.filter.status,
                                    operation: "="
                                },
                                {
                                    key: "courierNum",
                                    value: this.filter.courierNum,
                                    operation: "="
                                },
                                {
                                    key: "courierCode",
                                    value: this.filter.courierCode,
                                    operation: "="
                                },
                                {
                                    key: "dlvId",
                                    value: this.filter.dlvId,
                                    operation: "="
                                },
                            ])
                        },
                        headers: this.$root.getHeaders()
                    }
                ).then(response => {
                    if (response.body.success) {
                        this.entities = response.body.list;
                        this.pagination.totalElements = response.body.pagination.totalElements;
                    } else {
                        alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }
                }, response => {
                    this.$root.handleErrorResponse(response);
                });
            },
            remove(id) {
                this.$http.delete(this.$root.apiUrl + '/api/shipment/' + id, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success)
                            this.getEntities();
                        else
                            alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },
            paginationChange(pagination) {
                this.pagination.currentPage = pagination.page;
                this.pagination.pageSize = pagination.size;
                this.getEntities();
            },
            translateStatus(status) {
                let tr = this.statusOptions.find(value => value.value === status);
                if (tr)
                    return tr.text;
                else
                    return status;
            },
        }
    }
</script>

<style scoped>

</style>