<template>
    <b-container fluid>
        <b-row align-h="between">
            <b-col md="3" sm="6" class="my-1">
                <b-form-group horizontal label="Показывать" class="mb-0" :label-cols="6">
                    <b-form-select :options="pageOptions" v-model="pageSize" style="min-width: 100px;"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col md="4" sm="6" class="my-1">
                <b-pagination v-if="totalElements>pageSize" :total-rows="totalElements"
                              :per-page="pageSize" v-model="currentPage"
                              class="my-0">
                </b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Pagination",
        props: {
            totalElements: Number
        },
        watch: {
            pageSize() {
                this.$emit('change', {page: this.currentPage, size: this.pageSize});
            },
            currentPage() {
                this.$emit('change', {page: this.currentPage, size: this.pageSize});
            },
        },
        data() {
            return {
                pageOptions: [{
                    value: 20,
                    text: 'по 20'
                }, {
                    value: 50,
                    text: 'по 50'
                }, {
                    value: 100,
                    text: 'по 100'
                }],
                pageSize: 20,
                currentPage: 1
            }
        },
        methods: {}
    }
</script>

<style scoped>

</style>